<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  :format="DATE_FORMAT_YYYY_MM_DD"
                  v-model="bsnDate"
                  @change="onBsnDateChange"
                />
              </li>
              <li class="item text">
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(new Date(bsnDate)),
                  }"
                  >{{ getDayOfWeekCaption(new Date(bsnDate)) }}</span
                >) (<span
                  :style="{
                    color: getWeekdayWeekendCaptionColor(new Date(bsnDate)),
                  }"
                  >{{ getWeekdayWeekendCaption(new Date(bsnDate)) }}</span
                >)
              </li>
            </ul>
          </li>
          <li class="field groupName">
            <div class="title">단체명</div>
            <ul class="content">
              <li class="item input">
                <input-text v-model="grpName" @keydown.enter="getGroupList" />
              </li>
            </ul>
            <div class="lookup-lookup">
              <erp-button
                  button-div="GET"
                  @click.native="getGroupList"
              >
                조회
              </erp-button>
            </div>
          </li>
          <li class="field visitorNameLockerNumber">
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  v-model="findType"
                  :dataSource="findTypeOptions"
                  :allowFiltering="false"
                  :fields="findTypeFields"
                  cssClass="lookup-condition-dropdown"
                ></ejs-dropdownlist>
              </li>
              <li class="item">
                <input-text v-model="findKeyword" />
              </li>
            </ul>
            <div class="lookup-lookup">
              <erp-button
                  button-div="GET"
                  @click.native="findTeamVisit"
              >
                찾기
              </erp-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-01">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">단체팀 목록</div>
                <div class="header-caption">
                  [{{ numberWithCommas(groupTeamList.length) }}건]
                </div>
              </div>
            </div>
            <div class="section-body">
              <div class="body-data header">
                <div class="data-outer">
                  <div class="data-inner">
                    <ul class="data-content">
                      <li class="field accordion">
                        <!-- 필수 : required -->
                        <div class="title">접기/펼치기</div>
                      </li>
                      <li class="field number">
                        <!-- 필수 : required -->
                        <div class="title">NO</div>
                      </li>
                      <li class="field groupName">
                        <!-- 필수 : required -->
                        <div class="title">단체명</div>
                      </li>
                      <li class="field personnel">
                        <!-- 필수 : required -->
                        <div class="title">인원</div>
                      </li>
                      <li class="field gender">
                        <!-- 필수 : required -->
                        <div class="title">남/여</div>
                      </li>
                      <li class="field hole">
                        <!-- 필수 : required -->
                        <div class="title">홀</div>
                      </li>
                      <li class="field checkout">
                        <!-- 필수 : required -->
                        <div class="title">C/O</div>
                      </li>
                      <li class="field scroll">
                        <!-- 필수 : required -->
                        <div class="title">스크롤</div>
                      </li>
                    </ul>
                    <div class="border-left"></div>
                    <div class="border-right"></div>
                    <div class="border-top"></div>
                    <div class="border-bottom"></div>
                  </div>
                </div>
                <div class="border-left"></div>
                <div class="border-right"></div>
                <div class="border-top"></div>
                <div class="border-bottom"></div>
              </div>
              <div class="body-data body">
                <div class="data-outer">
                  <div class="data-inner">
                    <template
                      v-for="(groupTeam, groupTeamIdx) in groupTeamList"
                    >
                      <ul
                        class="data-content"
                        :class="
                          selectedGrpNo === groupTeam.grpNo
                            ? $t('className.frontCheckin.groupSelectedTeam')
                            : ''
                        "
                        :key="groupTeam.grpNo"
                      >
                        <li class="field accordion">
                          <div class="content">
                            <ul class="button">
                              <li
                                class="close"
                                v-if="isShowGroupListGrpNo(groupTeam.grpNo)"
                              >
                                <erp-button
                                  button-div="CLOSE"
                                  @click.native="
                                    toggleCurrentGroupList(
                                      $event,
                                      groupTeam.grpNo
                                    )
                                  "
                                >
                                  접기
                                </erp-button>
                              </li>
                              <li class="open" v-else>
                                <erp-button
                                  button-div="CLOSE"
                                  @click.native="
                                    toggleCurrentGroupList(
                                      $event,
                                      groupTeam.grpNo
                                    )
                                  "
                                >
                                  열기
                                </erp-button>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field number">
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group text">
                                {{ groupTeamIdx + 1 }}
                                <div class="validation">
                                  <div class="e-tip-content"></div>
                                  <div class="e-tip-top"></div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field groupName groupTeam">
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group text">
                                <div
                                  @click="
                                    onGroupTeamClicked($event, groupTeam.grpNo)
                                  "
                                >
                                  {{ groupTeam.grpNo }}
                                  {{ groupTeam.grpName }}({{
                                    groupTeam.teamCnt
                                  }}
                                  팀)
                                </div>
                                <div class="validation">
                                  <div class="e-tip-content"></div>
                                  <div class="e-tip-top"></div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field personnel">
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group text">
                                {{ groupTeam.visitCnt }}
                                <div class="validation">
                                  <div class="e-tip-content"></div>
                                  <div class="e-tip-top"></div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field gender">
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group text">
                                {{ groupTeam.maleCnt }} /
                                {{ groupTeam.femaleCnt }}
                                <div class="validation">
                                  <div class="e-tip-content"></div>
                                  <div class="e-tip-top"></div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field hole">
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group text">
                                <div class="validation">
                                  <div class="e-tip-content"></div>
                                  <div class="e-tip-top"></div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field checkout">
                          <div class="content">
                            <ul class="row">
                              <li class="item text">
                                <div class="validation">
                                  <div class="e-tip-content"></div>
                                  <div class="e-tip-top"></div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                      <template
                        v-for="groupTeamDetail in groupTeam.groupTeamDetailList"
                      >
                        <ul
                          class="data-content"
                          v-show="isShowGroupListGrpNo(groupTeam.grpNo)"
                          :key="groupTeamDetail.chkinId"
                        >
                          <li class="field accordion">
                            <div class="content"></div>
                          </li>
                          <li class="field number">
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group text">
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field groupName">
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group text">
                                  {{ groupTeamDetail.groupNum }}조 :
                                  {{ groupTeamDetail.startTime }}
                                  {{ groupTeamDetail.startCourseName }}
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field personnel">
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group text">
                                  {{ groupTeamDetail.visitCnt }}
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field gender">
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group text">
                                  {{ groupTeamDetail.maleCnt }} /
                                  {{ groupTeamDetail.femaleCnt }}
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field hole">
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group text">
                                  {{
                                    commonCodesGetComName(
                                      "HOLE_DIV",
                                      groupTeamDetail.teamHoleDiv
                                    )
                                  }}
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li
                            class="field checkout"
                            :style="
                              groupTeamDetail.teamChkoutFlag
                                ? {
                                    'background-color': commonCodesGetColorValue(
                                      'CHKIN_STATUS',
                                      'CHKOUT'
                                    ),
                                    color: '#ffffff',
                                    'text-align': 'center',
                                  }
                                : ''
                            "
                          >
                            <div class="content">
                              <ul class="row">
                                <li
                                  class="item form-group text"
                                  :class="[
                                    groupTeamDetail.teamChkoutFlag
                                      ? $t(
                                          'className.groupConf.groupConfCheckoutIsTrue'
                                        )
                                      : '',
                                  ]"
                                  style="width: 100%"
                                >
                                  {{ groupTeamDetail.chkoutTime }}
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </template>
                    </template>
                    <div class="border-left"></div>
                    <div class="border-right"></div>
                    <div class="border-top"></div>
                    <div class="border-bottom"></div>
                  </div>
                  <div class="empty" v-if="groupTeamList.length === 0">
                    <div>데이터가 없습니다</div>
                  </div>
                </div>
                <div class="border-left"></div>
                <div class="border-right"></div>
                <div class="border-top"></div>
                <div class="border-bottom"></div>
              </div>
            </div>
          </section>
        </div>
        <div class="article-right">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-02">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">단체팀 조 편성</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="save keyColor">
                    <erp-button
                        button-div="SAVE"
                        :is-icon-custom="true"
                        :is-shortcut-button="true"
                        @click.native="saveTeamVisitList"
                    >
                      저장
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <ejs-grid-wrapper
                ref="teamVisitsGrid"
                :provides="grid"
                :columns="teamVisitsGridOptions.columns"
                :dataSource="groupTeamVisitList"
                :enableHover="false"
                :selectionSettings="teamVisitsGridOptions.selectionSettings"
                :editSettings="teamVisitsGridOptions.editSettings"
                @cellSaved="teamVisitsGridCellSave"
                @queryCellInfo="teamVisitsGridQueryCellInfo"
                @actionComplete="onTeamVisitsGridActionComplete"
                @rowSelected="onTeamVisitsGridRowSelected"
              />
            </div>
          </section>
        </div>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.groupName .content .item.input {width: 180px;}
body .appContent .lookup-condition .field.visitorNameLockerNumber .content .item {width: 120px;}
body .appContent .body-article .article-left {width: 564px;}
body .appContent .body-article .article-right {width: calc(100% - 564px);}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
body .appContent .article-section.section-01 .body-data .field {width: calc((100% / 8) + 1px);}
body .appContent .article-section.section-01 .body-data .field.number {width: 40px;}
body .appContent .article-section.section-01 .body-data .field.groupName {width: 180px;}
body .appContent .article-section.section-01 .body-data .field.personnel {width: 90px;}
body .appContent .article-section.section-01 .body-data .field.gender {width: 60px;}
body .appContent .article-section.section-01 .body-data .field.hole {width: 50px;}
body .appContent .article-section.section-01 .body-data.header .field.checkout {width: calc((100% + 1px) - (457px - 1px));}
body .appContent .article-section.section-01 .body-data.body {height: calc(100% - 24px);}
body .appContent .article-section.section-01 .body-data.body .data-outer {height: 100%;overflow-y: scroll;}
body .appContent .article-section.section-01 .body-data.body >>> .data-content.dev-group-selected-team {background-color: #ecfaf0;}
body .appContent .article-section.section-01 .body-data.body .field.number .item.text {width: 100%;text-align: center;}
body .appContent .article-section.section-01 .body-data.body .field.groupName.groupTeam {cursor: pointer;}
body .appContent .article-section.section-01 .body-data.body .field.groupName.groupTeam .content .row .item.form-group.text:hover {text-decoration: underline;}
body .appContent .article-section.section-01 .body-data.body .field.checkout {width: calc((100% + 1px) - (457px - 1px - 17px));}
body .appContent .article-section.section-01 .body-data.body .empty {padding: 9px 12px 8px 12px;color: #bbb;}

/* Theme */
html body.theme-red .appContent .article-section.section-01 .body-data.body >>> .data-content.dev-group-selected-team {background-color: #faecf6;}
html body.theme-orange .appContent .article-section.section-01 .body-data.body >>> .data-content.dev-group-selected-team {background-color: #faf0ec;}
html body.theme-yellow .appContent .article-section.section-01 .body-data.body >>> .data-content.dev-group-selected-team {background-color: #f9faec;}
html body.theme-green .appContent .article-section.section-01 .body-data.body >>> .data-content.dev-group-selected-team {background-color: #ecfaf0;}
html body.theme-blue .appContent .article-section.section-01 .body-data.body >>> .data-content.dev-group-selected-team {background-color: #ecf6fa;}
html body.theme-indigo .appContent .article-section.section-01 .body-data.body >>> .data-content.dev-group-selected-team {background-color: #eeecfa;}
html body.theme-purple .appContent .article-section.section-01 .body-data.body >>> .data-content.dev-group-selected-team {background-color: #f8ecfa;}
html body.theme-black .appContent .article-section.section-01 .body-data.body >>> .data-content.dev-group-selected-team {background-color: #f3f3f3;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import numberTemplate from "@/views/common/template/GridNumberTemplate";
import InputDate from "@/components/common/datetime/InputDate";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import moment from "moment";

import {
  Edit,
  Resize,
  ForeignKey,
  Sort,
  Filter,
} from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";

import {
  commonCodesGetComName,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import {
  getFormattedDate,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
} from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import {
  getGroupList,
  getTeamList,
  saveTeamVisitList,
} from "@/api/frontManagementGroup";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "groupConf",
  components: {
    InputText,
    EjsGridWrapper,
    InputDate,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  created() {
    // 영업일자 날짜 설정
    const queryBsnDate = this.$route.query.bsnDate;
    if (queryBsnDate && moment(queryBsnDate, "YYYY-MM-DD", true).isValid()) {
      this.bsnDate = queryBsnDate;
    } else {
      this.bsnDate = getFormattedDate(new Date());
    }
  },
  mounted() {
    this.getGroupList();
  },
  data() {
    return {
      grid: [Edit, Resize, ForeignKey, Sort, Filter],
      DATE_FORMAT_YYYY_MM_DD,
      numberTemplate() {
        return {
          template: numberTemplate,
        };
      },
      bsnDate: null,
      grpName: null,
      findType: "visitName",
      findTypeOptions: [
        { comCode: "visitName", comName: "내장객명" },
        { comCode: "lockerNo", comName: "락카번호" },
      ],
      findTypeFields: { text: "comName", value: "comCode" },
      findKeyword: null,
      groupTeamList: [],
      groupTeamNoShowGrpNoList: [], // 단체팀 목록 접기/펴기를 위한 Array
      selectedGrpNo: null, // 단체팀 목록 select 표시를 위함
      groupTeamChkinIdList: [],
      groupTeamVisitList: [],
      teamVisitSelectedVisitId: null,
    };
  },
  computed: {
    isShowGroupListGrpNo() {
      return (grpNo) => {
        return !this.groupTeamNoShowGrpNoList.includes(grpNo);
      };
    },
    teamVisitsGridOptions() {
      return {
        selectionSettings: {
          checkboxOnly: false,
          type: "Single",
          mode: "Both",
        },
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        columns: [
          {
            field: "visitId",
            allowEditing: false,
            type: "string",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "groupNum",
            headerText: "조번호",
            allowEditing: true,
            width: 60,
            textAlign: "center",
            type: "number",
            format: "N",
            inputNumberProperty: { maxLength: 3 },
          },
          {
            field: "startTime",
            headerText: "출발시간",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center"
          },
          {
            field: "startCourse",
            headerText: "코스",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "COURSE_CODE",
          },
          {
            field: "holeDiv",
            headerText: "홀",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "HOLE_DIV",
          },
          {
            field: "visitName",
            headerText: "내장객명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "lockerNo",
            headerText: "락카번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "sexCode",
            headerText: "성별",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "SEX_CODE",
          },
          {
            field: "memberDiv",
            headerText: "회원구분",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "MEMBER_DIV",
          },
          {
            field: "joinGroupNo",
            headerText: "조인번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "caddieSetFlag",
            headerText: "캐디배치",
            allowEditing: false,
            type: "boolean",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            displayAsCheckBox: true,
          },
          {
            field: "chkoutFlag",
            headerText: "C/O",
            allowEditing: false,
            type: "boolean",
            minWidth: 16,
            width: 60,
            textAlign: "center",
            displayAsCheckBox: true,
          },
        ],
      };
    },
  },
  methods: {
    numberWithCommas,
    commonCodesGetComName,
    commonCodesGetColorValue,
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    getWeekdayWeekendCaption,
    getWeekdayWeekendCaptionColor,
    onBsnDateChange() {
      // 단체팀 목록 조회
      if (this.bsnDate) {
        this.getGroupList();
      }
    },
    async getGroupList() {
      const response = await getGroupList(this.bsnDate, this.grpName);
      this.groupTeamList = response.value.groupTeamList;
      this.groupTeamNoShowGrpNoList = [];

      // 조회한 단체팀 목록 중, 현재 선택한 정보가 없으면, 첫 번째 row를 선택 정보로
      if (
          this.groupTeamList.findIndex(
              (groupInfo) => groupInfo.grpNo === this.selectedGrpNo
          ) === -1
      ) {
        if (this.groupTeamList.length > 0) {
          this.selectedGrpNo = this.groupTeamList[0].grpNo;
        } else {
          this.selectedGrpNo = null;
        }
      }

      // 단체팀 조 편성 조회
      await this.getTeamList(this.selectedGrpNo);
    },
    async getTeamList(grpNo) {
      if (grpNo !== null) {
        const response = await getTeamList(this.bsnDate, grpNo);
        this.groupTeamChkinIdList = response.value.groupTeamChkinIdList;
        this.groupTeamVisitList = response.value.groupTeamVisitList;

        // 조회한 조 편성 내장객 목록 중, 현재 선택한 정보가 없으면, 첫 번째 row를 선택 정보로
        if (
            this.groupTeamVisitList.findIndex(
                (visitInfo) =>
                    visitInfo.visitId === this.teamVisitSelectedVisitId
            ) === -1
        ) {
          if (this.groupTeamVisitList.length > 0) {
            this.teamVisitSelectedVisitId = this.groupTeamVisitList[0].visitId;
          } else {
            this.teamVisitSelectedVisitId = null;
          }
        }
      } else {
        this.groupTeamChkinIdList = [];
        this.groupTeamVisitList = [];
      }
    },
    toggleCurrentGroupList(args, grpNo) {
      const grpNoIdx = this.groupTeamNoShowGrpNoList.indexOf(grpNo);
      if (grpNoIdx > -1) {
        this.groupTeamNoShowGrpNoList.splice(grpNoIdx, 1);
      } else {
        this.groupTeamNoShowGrpNoList.push(grpNo);
      }
    },
    async onGroupTeamClicked(args, grpNo) {
      this.selectedGrpNo = grpNo;

      // 단체팀 조 편성 조회
      await this.getTeamList(this.selectedGrpNo);
    },
    teamVisitsGridCellSave(args) {
      if (args.columnName === "groupNum") {
        if (args.previousValue !== args.value) {
          const selectedGridIdx = this.$refs.teamVisitsGrid
            .getDataRows()
            .findIndex((dataRow) => dataRow === args.cell.closest("tr"));

          if (args.rowData.caddieSetFlag) {
            // 캐디배치가 완료되면 수정 불가
            this.errorToast("캐디배치가 완료되었으므로, 수정하실 수 없습니다");
            this.$refs.teamVisitsGrid.updateCell(
              selectedGridIdx,
              "groupNum",
              args.previousValue
            );
          } else if (
            isNaN(args.value) ||
            args.value <= 0 ||
            args.value > this.groupTeamChkinIdList.length
          ) {
            // 변경할 값 validation
            this.errorToast("입력하실 수 없는 값입니다");
            this.$nextTick(() => {
              this.$refs.teamVisitsGrid.updateCell(
                selectedGridIdx,
                "groupNum",
                args.previousValue
              );
            });
          }
        }
      }
    },
    teamVisitsGridQueryCellInfo(args) {
      if (args.column.field === "groupNum") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }

      if (!(args.column.edit.parent.sortedColumns.length > 0)) {
        if (args.data.groupNumLineFlag) {
          args.cell.classList.add(
            this.$t("className.grid.devReservationPartDivLine")
          );
        }
      }
    },
    onTeamVisitsGridActionComplete(args) {
      if (args.requestType === "refresh") {
        // row select 처리
        const selectIdx = this.groupTeamVisitList.findIndex(
          (visitInfo) => visitInfo.visitId === this.teamVisitSelectedVisitId
        );
        this.$refs.teamVisitsGrid.selectRow(selectIdx);

        this.$refs.teamVisitsGrid.filterByColumn(
          "chkoutFlag",
          "equal",
          "false"
        );
      }
    },
    onTeamVisitsGridRowSelected(args) {
      // 선택시에, 현재 선택중인 visitId 갱신
      this.teamVisitSelectedVisitId = args.data.visitId;
    },
    findTeamVisit() {
      if (!this.bsnDate) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["영업일자"])
        );
      }
      if (this.findKeyword === null || this.findKeyword.trim() === "") {
        this.infoToast(this.$t("main.popupMessage.noSearchValue"));
        return;
      }
      const selectIdx = this.groupTeamVisitList.findIndex((visitInfo) =>
        visitInfo[this.findType].includes(this.findKeyword)
      );
      if (selectIdx === -1) {
        this.errorToast("검색한 내장객 자료가 존재하지 않습니다");
      } else {
        this.$refs.teamVisitsGrid.selectRow(selectIdx);
      }
    },
    async saveTeamVisitList() {
      // 수정할 데이터 체크 여부
      const teamVisitBatchChanges = this.$refs.teamVisitsGrid.getBatchChanges()
        .changedRecords;
      if (teamVisitBatchChanges.length === 0) {
        this.errorToast("수정할 데이터가 없습니다");
        return;
      }

      // TODO : 서버 수정된 후에는 수정한 데이터만 넘기는 방식으로 변경할 예정
      let groupTeamVisitListData = JSON.parse(
        JSON.stringify(this.groupTeamVisitList)
      );
      groupTeamVisitListData.forEach((teamInfo) => {
        const changedInfo = teamVisitBatchChanges.find(
          (teamInfoChanged) => teamInfoChanged.visitId === teamInfo.visitId
        );
        if (changedInfo !== undefined) {
          teamInfo.groupNum = changedInfo.groupNum;
        }
      });

      // 각 조별 갯수 체크 : 조 번호 당 1 ~ 5개까지만 허용
      let isVisitsNumPerTeamIsOK = true;
      let groupNumCheckArray = [
        ...Array(this.groupTeamChkinIdList.length).keys(),
      ].map((x) => x + 1);
      groupNumCheckArray.some((groupNumToCheck) => {
        const saveDataTeamListCount = groupTeamVisitListData.filter(
          (saveDataTeamInfo) => saveDataTeamInfo.groupNum === groupNumToCheck
        ).length;
        if (saveDataTeamListCount < 1 || saveDataTeamListCount > 5) {
          isVisitsNumPerTeamIsOK = false;
          return false;
        }
      });

      if (!isVisitsNumPerTeamIsOK) {
        this.errorToast("한 팀당 최대 인원은 5명입니다.");
        return;
      }

      groupTeamVisitListData = groupTeamVisitListData.filter(data => teamVisitBatchChanges.some(visit => visit.visitId === data.visitId));

      const groupTeamChkinIdList = this.groupTeamChkinIdList.filter(data =>
        groupTeamVisitListData.some(visit => visit.chkinId === data.chkinId || visit.groupNum === data.groupNum)
      );

      let saveData = {
        bsnDate: this.bsnDate,
        groupTeamChkinIdList: groupTeamChkinIdList,
        groupTeamVisitList: groupTeamVisitListData,
      };
      console.log('saveData.===>', saveData);

      await saveTeamVisitList(saveData);
      this.infoToast(this.$t("main.popupMessage.saved"));

      // 재조회
      await this.getGroupList();
    },
  },
};
</script>
