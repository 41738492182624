import request from "@/utils/request";

/**
 * 단체팀 List 조회
 * @param bsnDate
 * @param grpName
 * @return Promise
 */
export function getGroupList(bsnDate, grpName) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/group-conf/list`,
    method: "get",
    params: {
      bsnDate: bsnDate,
      grpName: grpName,
    },
  });
}

/**
 * 단체팀 조 편성 조회
 * @param bsnDate
 * @param grpNo
 * @return Promise
 */
export function getTeamList(bsnDate, grpNo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/front_management/group-conf/team-visit-list`,
    method: "get",
    params: {
      bsnDate: bsnDate,
      grpNo: grpNo,
    },
  });
}

/**
 * 단체팀 재구성 저장
 * @param data
 * @return Promise
 */
export function saveTeamVisitList(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/front_management/group-conf/team-visit-list",
    method: "put",
    data,
  });
}
